import React from "react"
import Footer from "../../components/footer"
import { graphql } from "gatsby"
import "../../styles/cdn.less"
import SEO from "../../components/SEO"
import { ProductNav } from "../../components/header/Header"
import {
  ProductSupport,
  SolutionTopBanner,
  ProductBlog,
  AnimationWrap,
} from "../../components/block"
import { ConsultationPopButton } from "../../components/form-biz"
import { MapContainer, Feedback, Solution } from "./bare-metal-cloud"
const loadText = require("src/utils").loadText
const static_data = loadText("cdn")
const staticPath = "/products/cdn"
const B1 = () => (
  <div className="cdn-b1">
    <AnimationWrap>
      <div className="cdn-b1-content page-middle mobile-padding">
        <div className="font-36 semi-bold t-color">{static_data.b1.title}</div>
        <div
          className="font-20 mt-24 block-middle description"
          style={{ maxWidth: "1000px" }}
        >
          {static_data.b1.description}
        </div>
      </div>
    </AnimationWrap>
  </div>
)
const B2 = () => (
  <div className="cdn-b2">
    <AnimationWrap>
      <div className="cdn-b2-content full-bg">
        <div className="left">
          <img
            src="/products/cdn/b2-pic.png"
            alt={static_data.b2.title}
            className="main-pic"
          />
        </div>
        <div className="right">
          <div className="font-36 semi-bold">{static_data.b2.title}</div>
          <div className="font-20 mt-24 description">
            {static_data.b2.description}
          </div>
        </div>
      </div>
    </AnimationWrap>
  </div>
)
const B4 = () => (
  <div className="cdn-b4">
    <AnimationWrap>
      <div className="cdn-b4-content full-bg">
        <div className="left">
          <img
            src="/products/cdn/b4-pic.png"
            alt={static_data.b4.title}
            className="main-pic"
          />
        </div>
        <div className="right">
          <div className="font-36 semi-bold t-color">
            {static_data.b4.title}
          </div>
          <div className="font-20 mt-24">{static_data.b4.description}</div>
        </div>
      </div>
    </AnimationWrap>
  </div>
)
const B3 = () => (
  <div className="cdn-b3">
    <div className="page-middle" style={{ position: "relative" }}>
      <div className="pic-container">
        <img src="/products/cdn/b3-woman.png" alt={static_data.b3.title} />
      </div>
      <div style={{ overflow: "hidden" }}>
        <AnimationWrap>
          <div className="cdn-b3-content">
            <div className="cdn-b3-bg ">
              <div className="right">
                <div className="font-36 semi-bold">{static_data.b3.title}</div>
                <div className="font-20 mt-24 description">
                  {static_data.b3.description}
                </div>
              </div>
            </div>
          </div>
        </AnimationWrap>
      </div>
    </div>
  </div>
)
const B5 = () => (
  <div className="cdn-b5">
    <AnimationWrap>
      <div className="cdn-b5-bg full-bg">
        <div className="left">
          <picture>
            <source
              srcSet="/products/cdn/b5-pic-m.png"
              media="(max-width: 640px)"
            />
            <img
              src="/products/cdn/b5-pic.png"
              alt={static_data.b5.title}
              className="main-pic"
            />
          </picture>
        </div>
        <div className="right">
          <div className="font-36 semi-bold title">{static_data.b5.title}</div>
          <div className="font-20 mt-24 description">
            {static_data.b5.description}
          </div>
        </div>
      </div>
    </AnimationWrap>
  </div>
)
const Improvement = () => (
  <div className="cdn-imp">
    <div className="cdn-imp-bg full-bg">
      <div className="font-36 semi-bold t-color">
        {static_data.improvement.title}
      </div>
      <div className="cdn-imp-itemContainer">
        {static_data.improvement.contents.map(
          ({ name, usecase, customer, result }, i) => (
            <div className="cdn-imp-item">
              <div className="left">
                <img src={`/products/cdn/imp-${i + 1}.jpg`} alt={name} />
              </div>
              <div className="right">
                <div className="top">
                  <div className="top-bg">
                    <div className="name">{name}</div>
                    <div className="label">
                      {static_data.improvement.subject.usecase}
                    </div>
                    <div>{usecase}</div>
                    <div className="label">
                      {static_data.improvement.subject.customer}
                    </div>
                    <div>{customer}</div>
                  </div>
                </div>
                <div className="bottom">
                  <div className="label">
                    {static_data.improvement.subject.result}
                  </div>
                  <div>{result}</div>
                </div>
              </div>
            </div>
          )
        )}
      </div>
      <div className="mt-56">
        <ConsultationPopButton source="cdn" buttonWidth={476}>
          {static_data.improvement.buttonText}
        </ConsultationPopButton>
      </div>
    </div>
  </div>
)
const Architecture = () => (
  <div className="cdn-architecture">
    <div className="cdn-architecture-bg full-bg">
      <div className="font-36 semi-bold">{static_data.architecture.title}</div>
      <div className="font-20 description">
        {static_data.architecture.description}
      </div>
      <div className="imgContainer">
        <img
          src="/products/cdn/architecture-pic.jpg"
          alt={static_data.architecture.title}
        />
      </div>
    </div>
  </div>
)
const Feature = () => {
  return (
    <div className="cdn-feature">
      <AnimationWrap>
        <div className="font-36 semi-bold t-color">
          {static_data.features.title}
        </div>
        <div className="cdn-feature-content full-bg">
          {static_data.features.content.map(({ name, description }, idx) => (
            <div className="cdn-feature-content-item">
              <div className="imgContainer">
                <picture>
                  <source
                    srcSet={`${staticPath}/feature-${idx + 1}-m.png`}
                    media="(max-width: 640px)"
                  />
                  <img
                    src={`${staticPath}/feature-${idx + 1}.png`}
                    alt={name}
                  />
                </picture>
                <div className="font-28 semi-bold only-mobile">{name}</div>
              </div>
              <div className="cdn-feature-content-itemContent textContainer">
                <div className="font-28 semi-bold t-color only-desktop">
                  {name}
                </div>
                <ul className="mt-24">
                  {description.map(d => (
                    <li className="font-20 mt-16">{d}</li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
      </AnimationWrap>
    </div>
  )
}
const B6 = () => (
  <div className="cdn-b6">
    <AnimationWrap>
      <div className="cdn-b6-bg full-bg">
        <div className="left">
          <img
            src="/products/cdn/b6-pic.png"
            alt={static_data.b6.title}
            className="main-pic"
          />
        </div>
        <div className="right">
          <div className="font-36 semi-bold t-color">
            {static_data.b6.title}
          </div>
          <div className="font-20 mt-24">{static_data.b6.description}</div>
        </div>
      </div>
    </AnimationWrap>
  </div>
)
const B7 = () => (
  <div className="cdn-b7">
    <AnimationWrap>
      <div className="cdn-b7-bg full-bg">
        <div className="left">
          <img
            src="/products/cdn/b7-pic.png"
            alt={static_data.b7.title}
            className="main-pic"
          />
        </div>
        <div className="right">
          <div className="font-36 semi-bold">{static_data.b7.title}</div>
          <div className="font-20 mt-24">{static_data.b7.description}</div>
        </div>
      </div>
    </AnimationWrap>
  </div>
)
const B8 = () => (
  <div className="cdn-b8">
    <AnimationWrap>
      <div className="cdn-b8-bg full-bg">
        <div className="font-36 semi-bold t-color">{static_data.b8.title}</div>
        <div className="font-20 description">{static_data.b8.description}</div>
      </div>
    </AnimationWrap>
  </div>
)
export default function Home({ data }) {
  const { allStrapiPosts, strapiTestimonials } = data
  return (
    <div className="cdn">
      <SEO {...static_data.seo} featuredImage="/featuredImage/cdn.jpg" />
      <SolutionTopBanner
        {...static_data.topBanner}
        bgColor="linear-gradient(137deg, #F6F9FF 0%, #E4F1FF 100%)"
        backgroundAnimated={true}
        animatedIcon={`${staticPath}/animatedIcon.svg`}
        iconBg={`${staticPath}/iconBg.svg`}
        bgPic="/products/cdn/topBanner-bg.svg"
        bannerPic={{
          src: `/products/cdn/topBanner-bg-m.svg`,
          style: {
            backgroundPosition: "top center",
          },
        }}
      />
      <ProductNav
        name={static_data.cdn}
        menus={static_data.navigation}
        minWidth={1100}
      />
      <a id="overview" />
      <B2 />
      <B1 />
      <B4 />
      <B6 />
      <B3 />
      <div style={{ paddingTop: "104px" }} className="support-container">
        <ProductSupport
          bgColor="linear-gradient(130deg, #EDF0FF 0%, #C1CBFE 100%)"
          bgPic={`${staticPath}/support-bg.svg`}
          woman={`${staticPath}/support-woman.png`}
          source="cdn"
        />
      </div>
      <B7 />
      <B8 />
      <B5 />
      {/* <a id="locations" />
      <MapContainer
        title={static_data.map.title}
        description={static_data.map.description}
        defaultSelected={[url_data.cdn.name]}
      /> */}
      <a id="keyFeatures" />
      {/* <Accelerate /> */}
      {/* <Improvement /> */}
      {/* <Architecture /> */}

      <Feature />

      <a id="testimonials" />
      <Feedback {...strapiTestimonials} />
      <a id="learnMore" />
      {/* <ProductAction
        bgColor="linear-gradient(177deg,rgba(244,249,254,1) 0%,rgba(224,240,255,1) 100%)"
        source="cdn"
        bgPic="/products/cdn/action-bg.svg"
        title={static_data.action.title}
        buttonText={static_data.action.buttonText}
        content={static_data.action.content.map((i, idx) => ({
          description: i,
          src: `/products/cdn/action-${idx + 1}.png`,
        }))}
      /> */}
      <Solution
        title={static_data.solution.title}
        description={static_data.solution.description}
      />
      <ProductBlog
        title={static_data.blog.title}
        blogs={allStrapiPosts.edges.map(({ node }) => node)}
      />
      <Footer />
    </div>
  )
}

export const query = graphql`
  {
    allStrapiPosts(
      filter: {
        categories: { elemMatch: { name: { eq: "Content Delivery Network" } } }
        published: { eq: true }
      }
    ) {
      edges {
        node {
          slug
          wp_date
          thumbnail {
            localFile {
              publicURL
            }
          }
          categories {
            name
          }
          created_at
          content

          title
          visibility
          published
          author {
            slug
            name
          }
          excerpt
        }
      }
    }
    strapiTestimonials(inPage: { eq: "cdn" }) {
      customerTitle
      customerName
      content
      companyPreview {
        localFile {
          publicURL
        }
      }
      logo {
        localFile {
          publicURL
        }
      }
      companyName
    }
  }
`
